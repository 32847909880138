<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      md="8"
      class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2>Daftar Akun Baru</h2>
    </b-col>
    <b-col cols="6">
      <ul>
        <li class="mb-3">
          <label>NIK <span class="required" /> </label>
          <b-form-input
            v-model="dataUser.nik"
            :state="v$.dataUser.nik.$dirty ? !v$.dataUser.nik.$error : null"
            name="nik"
            placeholder="Masukan NIK"
            class="mb-3"
          />
          <b-form-invalid-feedback
            :state="v$.dataUser.nik.$dirty ? !v$.dataUser.nik.$error : null"
          >
            {{ errorMessages.nik }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Nama Lengkap <span class="required" /> </label>
          <b-form-input
            v-model="dataUser.namaLengkap"
            name="nama"
            placeholder="Masukan Nama Lengkap"
            class="mb-3"
            :state="
              v$.dataUser.namaLengkap.$dirty
                ? !v$.dataUser.namaLengkap.$error
                : null
            "
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.namaLengkap.$dirty
                ? !v$.dataUser.namaLengkap.$error
                : null
            "
          >
            {{ errorMessages.namaLengkap }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Email <span class="required" /> </label>
          <b-form-input
            v-model="dataUser.email"
            name="email"
            type="email"
            placeholder="Masukan Alamat Email"
            class="mb-3"
            :state="v$.dataUser.email.$dirty ? !v$.dataUser.email.$error : null"
          />
          <b-form-invalid-feedback
            :state="v$.dataUser.email.$dirty ? !v$.dataUser.email.$error : null"
          >
            {{ errorMessages.email }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Jenis Kelamin <span class="required" /> </label>
          <b-form-select
            v-model="dataUser.jenisKelamin"
            :options="options"
            :state="
              v$.dataUser.jenisKelamin.$dirty
                ? !v$.dataUser.jenisKelamin.$error
                : null
            "
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.jenisKelamin.$dirty
                ? !v$.dataUser.jenisKelamin.$error
                : null
            "
          >
            {{ errorMessages.jenisKelamin }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Alamat Rumah <span class="required" /> </label>
          <b-form-textarea
            v-model="dataUser.alamat"
            :state="
              v$.dataUser.alamat.$dirty ? !v$.dataUser.alamat.$error : null
            "
            placeholder="Masukkan Alamat Rumah"
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.alamat.$dirty ? !v$.dataUser.alamat.$error : null
            "
          >
            {{ errorMessages.alamat }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <p>Tanggal Lahir <span class="required" /></p>
          {{ dataUser.tanggalLahir }}
          <datepicker
            class="bg-white"
            :bootstrap-styling="true"
            :clear-button="true"
            :format="customFormatter"
            v-model="dataUser.tanggalLahir"
            :state="
              v$.dataUser.tanggalLahir.$dirty
                ? !v$.dataUser.tanggalLahir.$error
                : null
            "
            name="tanggal_lahir"
            locale="id"
            placeholder="Pilih Tanggal Lahir"
          ></datepicker>
          <b-form-invalid-feedback
            :state="
              v$.dataUser.tanggalLahir.$dirty
                ? !v$.dataUser.tanggalLahir.$error
                : null
            "
          >
            {{ errorMessages.tanggalLahir }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Tempat Lahir <span class="required" /> </label>
          <b-form-input
            v-model="dataUser.tempatLahir"
            name="nama"
            :state="
              v$.dataUser.tempatLahir.$dirty
                ? !v$.dataUser.tempatLahir.$error
                : null
            "
            placeholder="Masukan Tempat Lahir"
            class="mb-3"
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.tempatLahir.$dirty
                ? !v$.dataUser.tempatLahir.$error
                : null
            "
          >
            {{ errorMessages.tempatLahir }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Provinsi <span class="required" /> </label>
          <v-select
            v-model="dataUser.province"
            placeholder="Pilih Provinsi"
            :options="provinces"
            :state="
              v$.dataUser.province.$dirty ? !v$.dataUser.province.$error : null
            "
            :reduce="(option) => option"
            label="name"
            @input="handleInputProvince"
            @change="getAllProvinces"
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.province.$dirty ? !v$.dataUser.province.$error : null
            "
          >
            {{ errorMessages.province }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Kota/Kabupaten <span class="required" /> </label>
          <v-select
            v-model="dataUser.city"
            placeholder="Pilih Kota"
            :options="cities"
            :state="v$.dataUser.city.$dirty ? !v$.dataUser.city.$error : null"
            :reduce="(option) => option"
            label="name"
            @input="handleInputCity"
            @change="getAllCities"
          />
          <b-form-invalid-feedback
            :state="v$.dataUser.city.$dirty ? !v$.dataUser.city.$error : null"
          >
            {{ errorMessages.city }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Kecamatan <span class="required" /> </label>
          <v-select
            v-model="dataUser.district"
            placeholder="Pilih Kecamatan"
            :options="districts"
            :state="
              v$.dataUser.district.$dirty ? !v$.dataUser.district.$error : null
            "
            :reduce="(option) => option"
            label="name"
            @input="handleInputDistrict"
            @change="getAllDistricts"
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.district.$dirty ? !v$.dataUser.district.$error : null
            "
          >
            {{ errorMessages.district }}
          </b-form-invalid-feedback>
        </li>
      </ul>
    </b-col>
    <b-col cols="6">
      <ul>
        <li class="mb-3">
          <label>Kelurahan <span class="required" /> </label>
          <v-select
            v-model="dataUser.village"
            placeholder="Pilih Kelurahan"
            :state="
              v$.dataUser.village.$dirty ? !v$.dataUser.village.$error : null
            "
            :options="villages"
            :reduce="(option) => option"
            label="name"
            @input="handleInputVillage"
            @change="getAllVillages"
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.village.$dirty ? !v$.dataUser.village.$error : null
            "
          >
            {{ errorMessages.village }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>No. Telp Rumah/HP <span class="required" /> </label>
          <b-form-input
            v-model="dataUser.noTelpRumah"
            name="telp"
            placeholder="Masukan Nomor Telepon"
            class="mb-3"
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.noTelpRumah.$dirty
                ? !v$.dataUser.noTelpRumah.$error
                : null
            "
          >
            {{ errorMessages.noTelpRumah }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Alamat Kantor</label>
          <b-form-textarea
            v-model="dataUser.alamatKantor"
            placeholder="Masukkan Alamat Kantor"
          />
        </li>
        <li class="mb-3">
          <label>No. Telp Kantor</label>
          <b-form-input
            v-model="dataUser.noTelpKantor"
            name="contact_office"
            placeholder="Masukan Nomor Telepon Kantor"
            class="mb-3"
          />
        </li>
        <li class="mb-3">
          <label>Pekerjaan<span class="required" /> </label>
          <b-form-select
            v-model="dataUser.pekerjaan"
            :options="optionsPekerjaan"
            :state="
              v$.dataUser.pekerjaan.$dirty
                ? !v$.dataUser.pekerjaan.$error
                : null
            "
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.pekerjaan.$dirty
                ? !v$.dataUser.pekerjaan.$error
                : null
            "
          >
            {{ errorMessages.pekerjaan }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Jumlah penghargaan yang diterima </label>
          <b-form-select
            v-model="dataUser.penghargaan"
            :options="optionsReward"
            :state="
              v$.dataUser.penghargaan.$dirty
                ? !v$.dataUser.penghargaan.$error
                : null
            "
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.penghargaan.$dirty
                ? !v$.dataUser.penghargaan.$error
                : null
            "
          >
            {{ errorMessages.penghargaan }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label
            >Bersediakah anda donor pada waktu puasa? <span class="required"
          /></label>
          <div class="d-flex">
            <b-form-radio-group v-model="dataUser.isReadyDonorWhenFasting">
              <b-form-radio
                v-model="dataUser.isReadyDonorWhenFasting"
                value="1"
              >
                Ya
              </b-form-radio>
              <b-form-radio
                v-model="dataUser.isReadyDonorWhenFasting"
                value="0"
              >
                Tidak
              </b-form-radio>
            </b-form-radio-group>
            <b-form-invalid-feedback
              v-if="
                v$.dataUser.isReadyDonorWhenFasting.$dirty &&
                v$.dataUser.isReadyDonorWhenFasting.$error
              "
            >
              {{ errorMessages.isReadyDonorWhenFasting }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li class="mb-3">
          <label
            >Bersediakah saudara donor saat dibutuhkan keperluan tertentu
            (diluar donor rutin)? <span class="required"
          /></label>
          <div class="d-flex">
            <b-form-group
              v-model="dataUser.isReadyDonorOutsideRoutine"
              :state="
                v$.dataUser.isReadyDonorOutsideRoutine.$dirty
                  ? !v$.dataUser.isReadyDonorOutsideRoutine.$error
                  : null
              "
            >
              <b-form-radio-group v-model="dataUser.isReadyDonorOutsideRoutine">
                <b-form-radio
                  v-model="dataUser.isReadyDonorOutsideRoutine"
                  value="1"
                >
                  Ya
                </b-form-radio>
                <b-form-radio
                  v-model="dataUser.isReadyDonorOutsideRoutine"
                  value="0"
                >
                  Tidak
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-invalid-feedback
              :state="
                v$.dataUser.isReadyDonorOutsideRoutine.$dirty
                  ? !v$.dataUser.isReadyDonorOutsideRoutine.$error
                  : null
              "
            >
              {{ errorMessages.isReadyDonorOutsideRoutine }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li class="mb-3">
          <label>Password <span class="required" /> </label>
          <b-form-input
            v-model="dataUser.password"
            name="password"
            type="password"
            placeholder="Masukan Password"
            class="mb-3"
            :state="
              v$.dataUser.password.$dirty ? !v$.dataUser.password.$error : null
            "
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.password.$dirty ? !v$.dataUser.password.$error : null
            "
          >
            {{ errorMessages.password }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Konfirmasi Password <span class="required" /> </label>
          <b-form-input
            v-model="dataUser.c_password"
            name="password"
            type="password"
            placeholder="Masukan Ulang Password"
            class="mb-3"
            :state="
              v$.dataUser.c_password.$dirty
                ? !v$.dataUser.c_password.$error
                : null
            "
          />
          <b-form-invalid-feedback
            :state="
              v$.dataUser.c_password.$dirty
                ? !v$.dataUser.c_password.$error
                : null
            "
          >
            {{ errorMessages.c_password }}
          </b-form-invalid-feedback>
        </li>
        <b-col class="d-flex justify-content-end mb-3">
          <b-button variant="danger" class="mr-3" @click="$router.go(-1)">
            Batal
          </b-button>
          <b-button :disabled="isLoading" variant="info" @click="addUser">
            <b-spinner v-if="isLoading" small label="Loading" />
            Daftar
          </b-button>
        </b-col>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormSelect,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BFormGroup,
  BSpinner,
} from "bootstrap-vue";

import vSelect from "vue-select";
import fieldMappings from "../data/fieldMappings";
import PendonorDataService from "../../../../../api/manageuser/manageUserAPI";
import Datepicker from "vuejs-datepicker";
import moment from "moment/moment";

export default {
  name: "AddUser",
  setup: () => ({ v$: useVuelidate() }),
  components: {
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BFormSelect,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BFormRadio,
    BSpinner,
    Datepicker,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      options: [
        { value: "male", text: "Laki-laki" },
        { value: "female", text: "Perempuan" },
      ],
      optionsPekerjaan: [
        { value: null, text: "Pilih Pekerjaan" },
        { value: "TNI/POLRI", text: "TNI/POLRI" },
        { value: "Pegawai Negeri/Swasta", text: "Pegawai Negeri/Swasta" },
        { value: "Petani Buruh", text: "Petani Buruh" },
        { value: "Wiraswasta", text: "Wiraswasta" },
        { value: "Mahasiswa", text: "Mahasiswa" },
        { value: "Pedagang", text: "Pedagang" },
        { value: "Lain-lain", text: "Lain-lain" },
      ],
      optionsReward: [
        { value: null, text: "Pilih Penghargaan" },
        { value: "0", text: "Belum Ada" },
        { value: "10", text: "10  X" },
        { value: "25", text: "25X" },
        { value: "50", text: "50X" },
        { value: "75", text: "75X" },
        { value: "100", text: "100x" },
      ],
      optionsProvince: [],
      optionsCities: [],
      dataUser: {
        noKartuDonor: "",
        nik: "",
        email: "",
        namaLengkap: "",
        jenisKelamin: "",
        tanggalLahir: "",
        tempatLahir: "",
        alamat: "",
        province: "",
        city: "",
        district: "",
        village: "",
        noTelpRumah: "",
        noTelpKantor: "",
        pekerjaan: null,
        alamatKantor: "",
        penghargaan: null,
        isReadyDonorWhenFasting: false,
        isReadyDonorOutsideRoutine: false,
        password: "",
        c_password: "",
      },
      state: {
        selectedProvince: "",
        selectedCity: "",
        selectedDistricts: "",
        selectedVillages: "",
      },
      provinces: [],
      cities: [],
      districts: [],
      villages: [],
      errorMessages: {},
      busy: false,
    };
  },
  validations() {
    return {
      dataUser: {
        nik: { required },
        namaLengkap: { required },
        email: { required },
        jenisKelamin: { required },
        alamat: { required },
        tanggalLahir: { required },
        tempatLahir: { required },
        province: { required },
        city: { required },
        district: { required },
        village: { required },
        noTelpRumah: { required },
        pekerjaan: { required },
        penghargaan: { required },
        isReadyDonorWhenFasting: { required },
        isReadyDonorOutsideRoutine: { required },
        password: { required },
        c_password: { required },
      },
    };
  },
  watch: {
    "state.selectedProvince": {
      handler() {
        this.getAllCities();
      },
      immediate: true,
    },
    "state.selectedCity": {
      handler() {
        this.getAllDistricts();
      },
      immediate: true,
    },
    "state.selectedDistricts": {
      handler() {
        this.getAllVillages();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getAllProvinces();
  },
  methods: {
    customFormatter(date) {
      return moment(date).subtract(1, "days").format("YYYY-MM-DD");
    },
    async addUser() {
      const dataUser = {
        no_card: this.dataUser.noKartuDonor,
        nik: this.dataUser.nik,
        email: this.dataUser.email,
        name: this.dataUser.namaLengkap,
        sex: this.dataUser.jenisKelamin,
        birth_date: moment(this.dataUser.tanggalLahir).format("YYYY-MM-DD"),
        birth_place: this.dataUser.tempatLahir,
        address: this.dataUser.alamat,
        province: this.dataUser.province,
        city: this.dataUser.city,
        village: this.dataUser.village,
        district: this.dataUser.district,
        phone: this.dataUser.noTelpRumah,
        phone_office: this.dataUser.noTelpKantor,
        job: this.dataUser.pekerjaan,
        address_office: this.dataUser.alamatKantor,
        award: this.dataUser.penghargaan,
        ready_fasting: this.dataUser.isReadyDonorWhenFasting,
        ready_donor: this.dataUser.isReadyDonorOutsideRoutine,
        password: this.dataUser.password,
        c_password: this.dataUser.c_password,
      };

      await PendonorDataService.add(dataUser)
        .then((response) => {
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
            // Show a success toast
            this.$router.go(-1);
            this.$bvToast.toast("Pendonor Berhasil Registrasi", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          }, 500);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            const responseData = err.response.data;

            if (responseData.success === false && responseData.message) {
              const errorMessage = responseData.message;

              for (const field in this.dataUser) {
                const errorField = field;

                if (this.v$.dataUser[errorField]) {
                  this.v$.dataUser[errorField].$error = true;
                  this.v$.dataUser[errorField].$touch();

                  this.$set(
                    this.errorMessages,
                    errorField,
                    fieldMappings[errorField],
                    errorMessage
                  );
                }
              }
            }
          }
        });
    },
    getAllProvinces() {
      PendonorDataService.getListProvinces()
        .then((response) => {
          this.provinces = response.data.data;
        })
        .catch((err) => console.error(err));
    },
    handleInputProvince(selectedValue) {
      this.state.selectedProvince = selectedValue.id;
      this.dataUser.province = selectedValue.name;
    },
    getAllCities() {
      if (this.state.selectedProvince) {
        PendonorDataService.getListCities(this.state.selectedProvince)
          .then((response) => {
            this.cities = response.data.data;
          })
          .catch((err) => console.error(err));
      }
    },
    handleInputCity(selectedValue) {
      this.state.selectedCity = selectedValue.id;
      this.dataUser.city = selectedValue.name;
    },
    getAllDistricts() {
      if (this.state.selectedCity) {
        PendonorDataService.getListDistricts(this.state.selectedCity)
          .then((response) => {
            this.districts = response.data.data;
          })
          .catch((err) => console.error(err));
      }
    },
    handleInputDistrict(selectedValue) {
      this.state.selectedDistricts = selectedValue.id;
      this.dataUser.district = selectedValue.name;
    },
    getAllVillages() {
      if (this.state.selectedDistricts) {
        PendonorDataService.getListVillages(this.state.selectedDistricts)
          .then((response) => {
            this.villages = response.data.data;
          })
          .catch((err) => console.error(err));
      }
    },
    handleInputVillage(selectedValue) {
      this.state.selectedVillage = selectedValue.id;
      this.dataUser.village = selectedValue.name;
    },
  },
};
</script>
