const fieldMappings = {
  noKartuDonor: 'No. Kartu Donor harus diisi',
  namaLengkap: 'Nama Lengkap harus diisi',
  email: 'Email harus diisi',
  nik: 'NIK harus diisi',
  jenisKelamin: 'Jenis Kelamin harus diisi',
  alamat: 'Alamat harus diisi',
  tanggalLahir: 'Tanggal Lahir harus diisi',
  tempatLahir: 'Tempat Lahir harus diisi',
  province: 'Provinsi harus diisi',
  city: 'Kota harus diisi',
  district: 'Kecamatan harus diisi',
  village: 'Kelurahan harus diisi',
  noTelpRumah: 'No. Telepon Rumah harus diisi',
  noTelpKantor: 'No. Telepon Kantor harus diisi',
  pekerjaan: 'Pekerjaan harus diisi',
  alamatKantor: 'Alamat Kantor harus diisi',
  penghargaan: 'Penghargaan harus diisi',
  isReadyDonorOutsideRoutine: 'Ketersediaan harus diisi',
  isReadyDonorWhenFasting: 'Ketersediaan harus diisi',
  password: 'Password harus diisi',
  c_password: 'Konfirmasi password harus diisi'
};

export default fieldMappings